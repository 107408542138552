import React from 'react';
import { ReactComponent as SpinnerSvg } from '../../asstes/spinner.svg'; 
import "./style.css";

const Spinner = () => {
  return (
    <div className={"spinner-conteiner"}>
        <SpinnerSvg />
    </div>
  )
}

export default Spinner