import { Navigate } from "react-router-dom";
import Home from "../pages/Home";
import SignIn from "../pages/SignIn";

const routes = [
    {
        path: "/",
        element: Home,
        title: "Home",
    },
    {
        path: "/admin",
        element: SignIn,
        title: "Admin",
    },

]

export const PrivateRoute = ({ children }) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
        return <Navigate to="/" replace />;
      }
    
      return children;
  };

export default routes;