import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import "./style.css"
function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleChange = (event) => {
    const selectedLang = event.target.value;
    setSelectedLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
    localStorage.setItem("selectedLanguage", selectedLang);
  };

  return (
      <Select
        value={selectedLanguage}
        onChange={handleChange}
        className={"languge_switcher"}
        label={"language Switcher"}
      >
        <MenuItem className={"menu-item"} value="en">EN</MenuItem>
        <MenuItem className={"menu-item"} value="fr">FR</MenuItem>
      </Select>
  );
}

export default LanguageSwitcher;
