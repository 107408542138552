import React, { lazy, useContext } from 'react'
import { DataContext } from '../Provider'
import Projects from "../components/Gallery";
import {Box} from "@mui/material";
import ImageCarousel from "../components/Carousel";
import VerticalLine from "../components/VerticalLine";
import FacebookCustomerChat from "../components";
import { useTranslation } from 'react-i18next';
import Contact from './Contact';
import About from './About';
import Parallax from './Parallax';

const Home = () => {
  const {data: {carouselState: {toggle}}} = useContext(DataContext);
  const { t } = useTranslation();

  return (
      <div>
          <Box id="home" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column" }} >
            <Parallax />
          </Box>
          <VerticalLine text={t("projects")}/>
          <Box id="projects">
            <Projects />
          </Box>
          <VerticalLine text={t("about")}/>
          <Box id="about">
            <About />
          </Box>
          <VerticalLine text={t("contact")}/>
          <Box id="contact">
          < Contact/>
          </Box>
        {toggle && <ImageCarousel />}
        <FacebookCustomerChat />
      </div>
  )
}

export default Home;