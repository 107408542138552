import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Select, MenuItem, FormControlLabel, Checkbox, Snackbar, Slide } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { useStorage } from '../../hooks/useStorage';
import { Alert } from '@mui/material';

const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 500
  },
  inputContainer: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: "100%"
  },
  selectContainer: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: "capitalize"
  },
  submitButton: {
    margin: '20px 0',
  },
});

const UploadForm = () => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState('');
  // const [url, setUrl] = useState('');
  const [url_360, setUrl_360] = useState('');
  const [description, setDescription] = useState('');
  const [option, setOption] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [is360, setIs360] = useState(false);
  const [done, setDone] = useState(false);

  const handleFileChange = (newFiles) => {
    setFiles([...files, ...newFiles]);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  // const handleUrlChange = (event) => {
  //   setUrl(event.target.value);
  // };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsVideo(event.target.checked);
  };

  const handleCheckboxChangeFor360 = (event) => {
    setIs360(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDone(true);
};

const handleUrl360Change = (event) => {
  setUrl_360(event.target.value);
};

  const options = [
    { title: 'exterior' },
    { title: 'interior' },
    { title: 'products' },
    { title: 'animations' },
    { title: 'hdri' },
  ];

  const {progress} = useStorage(done, files[0], {title, description, option, isVideo, is360, url_360});


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // navigate(0);
  };

  return (
    <>
    <form className={classes.formContainer} onSubmit={handleSubmit}>
      <DropzoneArea
        acceptedFiles={['image/*', 'video/*']}
        filesLimit={1}
        maxFileSize={150000000}
        onChange={handleFileChange}
        showPreviewsInDropzone={true}
      />
      <div className={classes.inputContainer}>
        <TextField
          label="Title"
          variant="outlined"
          value={title}
          onChange={handleTitleChange}
          style={{width: "100%", margin: "5px"}}
        />
        <TextField
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
          style={{width: "100%", margin: "5px"}}
        />
         <TextField
          label="360 url"
          variant="outlined"
          value={url_360}
          onChange={handleUrl360Change}
          style={{width: "100%", margin: "5px"}}
        />
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={isVideo}
            onChange={handleCheckboxChange}
            name="isVideo"
            color="primary"
          />
        }
        label="Video"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={is360}
            onChange={handleCheckboxChangeFor360}
            name="is360"
            color="primary"
          />
        }
        label="360"
      />
      <div className={classes.selectContainer}>
        <Select value={option} onChange={handleOptionChange} displayEmpty>
          <MenuItem value="" disabled>
            Select an option
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.title} value={option.title} style={{textTransform: "capitalize"}}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        type="submit"
      >
        Submit
      </Button>
    </form>
     <Snackbar
            open={progress === 100 }
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={1000}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 250, exit: 1000 }}
            TransitionProps={{ enter: true, exit: false }}
            onClose={handleClose}
            sx={{
                color: "secondary", "& .MuiSnackbarContent-root": { backgroundColor: "green" }
            }}
        >
            <Alert severity="success" sx={{ width: "100%" }}>
                This is a success message!
            </Alert>
      </Snackbar>
    </>
  );
};

export default UploadForm;