import React from "react";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";

const VerticalLine = ({text = "value"}) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            height="150px"
        >
            <Box
                width="0.5px"
                height="130px"
                bgcolor="#b9b9b9" />
            <Typography style={{marginTop: "5px"}} className="title-text">
                {text}
            </Typography>

        </Box>
    );
};

export default VerticalLine;