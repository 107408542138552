import React, { useState } from 'react'
import { Alert, Box, Button, Container, IconButton, Slide, Snackbar, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';

const SignIn = () => {
    const navigate = useNavigate();
    const [openResult, setOpenResult] = useState({success: false, error: false});

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpenResult({success: false, error: false});
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);


        signInWithEmailAndPassword(auth, data.get("email"), data.get("password"))
          .then((userCredential) => {
            setOpenResult({success: true, error: false});
            const user = userCredential.user;
            localStorage.setItem("accessToken", user.accessToken);
            setTimeout(() => {
                navigate("/admin-panel");
            }, 1000)
          })
          .catch(() => {
            setOpenResult({success: false, error: true});
            localStorage.setItem("accessToken", "");
          });
    };

  return (
    <Container component="main" maxWidth="xs">
        <Snackbar
            open={openResult.success}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={1000}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 250, exit: 1000 }}
            TransitionProps={{ enter: true, exit: false }}
            onClose={handleClose}
            sx={{
                color: "secondary", "& .MuiSnackbarContent-root": { backgroundColor: "green" }
            }}
        >
            <Alert severity="success" sx={{ width: "100%" }}>
                This is a success message!
            </Alert>
      </Snackbar>
      <Snackbar
            open={openResult.error}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={1000}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 250, exit: 1000 }}
            TransitionProps={{ enter: true, exit: false }}
            onClose={handleClose}
            sx={{
                color: "secondary", "& .MuiSnackbarContent-root": { backgroundColor: "red" }
            }}
        >
            <Alert severity="error" sx={{ width: "100%" }}>
                Something gone wrong!
            </Alert>
      </Snackbar>
      <Box
        sx={{  
          marginTop: 25,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default SignIn