import React from 'react'
import UploadForm from '../components/UploadForm'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
  },
});

const Admin = () => {
  const classes = useStyles();
  

  return (
    <div className={classes.container}>
      <UploadForm />
    </div>
  )
}

export default Admin