import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import Logo from "../Logo";
import LanguageSwitcher from "../LanguageSwitcher";
import "./style.css";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

function Navbar(props) {
    const theme = useTheme();
    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const { t } = useTranslation();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleClickScroll = (id = "home") => {
        const element = id === "home" ? document.getElementById(id) : document.getElementById(id).previousElementSibling;

        if (element) {
            element.scrollIntoView({behavior: "smooth", block: 'start'});
        }
        if(!mobileOpen) return null

        setTimeout(() => {
            handleDrawerToggle()
        }, 1000)
    };

    const navigations = [
        {
            path: "/",
            title: "home",
        },
        {
            path: "/projects",
            title: "projects",
        },
        {
            path: "/about",
            title: "about",
        },
        {
            path: "/contact",
            title: "contact",
        },
    ];

    const drawer = (
        <Box sx={{textAlign: "center"}}>
            <Typography variant="h6" sx={{my: 2}}>
                <Logo/>
            </Typography>
            <Divider/>
            <List>
                {navigations.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton sx={{textAlign: "center"}}>
                                <ListItemText className={"arrow-to-scroll"} onClick={() => handleClickScroll(`${item.title}`)} primary={t(item.title)}/>
                            </ListItemButton>
                        </ListItem>
                    )
                )}
            </List>
            <LanguageSwitcher/>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box className="navbar" sx={{display: "flex"}}>
            <CssBaseline/>
            <AppBar component="nav" className={"nav-bar"} style={{boxShadow: "none"}}>
                <Toolbar className={"toolbar"}>
                    <IconButton
                        color={theme.palette.primary.main}
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: "none"}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{flexGrow: 1, display: {xs: "none", sm: "flex"}}}
                    >
                        <Logo/>
                    </Typography>
                    <Box sx={{display: {xs: "none", sm: "block"}}}>
                        {navigations.map((item, index) => (
                            <Button className={"arrow-to-scroll"} onClick={() => handleClickScroll(`${item.title}`)}
                                    key={index} sx={{color: theme.palette.primary.color}}>
                                {t(item.title)}
                            </Button>
                        ))}
                        <LanguageSwitcher/>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: "block", sm: "none"},
                        "& .MuiDrawer-paper": {boxSizing: "border-box", width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

Navbar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Navbar;