

import React from 'react'
import ImageParallax from '../components/ParallaxBox'
import parallaxImg from "../asstes/parallax.webp"
import { useTranslation } from 'react-i18next'

const Parallax = () => {
  const { t } = useTranslation()
  return (
        <ImageParallax descriptionText={t("visual")} headerText={"3D VISION STUDIO"} imageUrl={parallaxImg} />
  )
}

export default Parallax;