import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './en.json'; // Import English translations
import frTranslation from './fr.json'; // Import French translations

const LANG_STORAGE_KEY = 'selectedLanguage';

const getStoredLanguage = () => {
  const storedLang = localStorage.getItem(LANG_STORAGE_KEY);
  return storedLang ? storedLang : 'en'; // Use 'en' as the default language
};


// Configure i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
  lng: getStoredLanguage(), // Set default language
  fallbackLng: 'en', // Set fallback language
  interpolation: { escapeValue: false },
});

export default i18n;