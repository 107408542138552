import React, {useContext} from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DataContext } from '../../Provider';
import "./style.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ImageCarousel = () => {
  const {data, setData} = useContext(DataContext);

  const handleClose = () => {
    setData({
      ...data,
      carouselState: { selectedItem: null, toggle: false },
    });
  }

  console.log(data, "data")

  return (
    <>
      <CloseRoundedIcon className="close-icon" onClick={handleClose} />
      <Carousel infiniteLoop={true} transitionTime={400} autoPlay={false} useKeyboardArrows showThumbs={false} showArrows className="carousel" selectedItem={data.carouselState.selectedItem}>
      {data.images.map((item, index) =>
          item?.isVideo ? (
            <video controls key={index} src={item.url} autoPlay muted loop />
          ): item?.is360 ? (
            <iframe src={item.url_360} className="for_360_slide" title={item.title} />
          ) : (
            <img key={index} src={item.url} alt={item.title} />
          )
        )}
      </Carousel>
    </>
    
  );
}

export default ImageCarousel;