import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import { Container } from '@mui/material';
import pdf from "../../asstes/vision_studio.pdf";
import { makeStyles } from "@material-ui/core/styles";

const Footer = () => {

  const useStyles = makeStyles((theme) => ({
    linkes: {
      margin: '0 8px', 
      cursor: "pointer",
      textAlign: "center",
      display: "inline-block",
      cursor: "pointer",
      textDecoration: "none",
      color: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icons: {
      margin: '0 8px', 
    },
    container: {
      height: '64px',
      position: 'fixed !important',
      bottom: '0',
      width: '100%',
      maxWidth: '100% !important',
      background: 'white',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: "1111",
    }, 
    text: {
      "@media (max-width: 800px)": {
        display: "none",
       }

    }
  }));

  const classes = useStyles();
  
  return (
    <Container className={classes.container}>
        <a className={classes.linkes} href="tel:+900300400"><PhoneIcon className={classes.icons}/><span className={classes.text}>+ 33 6 35 02 62 94</span></a>
        <a className={classes.linkes} href="mailto:contact@3dvisionstudio.com"><EmailIcon className={classes.icons} /><span className={classes.text}>contact@3dvisionstudio.com</span></a>
        <a className={classes.linkes} href={pdf} download="3D_Vision_Studio_Portfolio.pdf"><DownloadIcon className={classes.icons}  /><span className={classes.text}>Download Portfolio</span></a>
    </Container>
  )
}

export default Footer