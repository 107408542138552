import { useRef, useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import emailjs from '@emailjs/browser'; 
import { useTranslation } from "react-i18next";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();
  const { t } = useTranslation();

  const useStyles = makeStyles((theme) => ({
    textField: {
      '& .MuiInputBase-root.Mui-focused': {
        borderColor: 'gray', // Change to your desired color
        boxShadow: "0px 1px 2px black"
      },
      '& .MuiInputBase-root.Mui-focused.Mui-active': {
        borderColor: 'gray', // Change to your desired color
      },
    },
  }));

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w8fmtxo', 'template_svtx2dr', form.current, 'jlSy3zDvwSVkA5EOi')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
        <Typography variant="h5" align="center" mb={2}>
          {t("contact_us")}
        </Typography>
        <form ref={form} onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={t("name")}
            name="user_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
            className={classes.textField}
          />
          <TextField
            fullWidth
            label={t("email")}
            name="user_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            className={classes.textField}
          />
          <TextField
            fullWidth
            label={t("message")}
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
            className={classes.textField}
          />
          <Button variant="contained" type="submit" sx={{ mt: 2 }}>
            {t("submit")}
          </Button>
        </form>
      </Box>
    </Box>
  );
}