import React from 'react'
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
// import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    logo: {
      width: 89,
      height: 40,
      objectFit: 'contain',
      cursor: "pointer"
    },
});
  

const Logo = () => {
    const theme = useTheme();
    const classes = useStyles();
    // const navigate = useNavigate();

  return (
    <img src={ theme.palette.logo.main } alt="logo" className={classes.logo} />
  )
}

export default Logo