import React from 'react';
import { Box, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import parallaxImg from "../asstes/parallax.webp"

const primary = purple[500]; // #f44336

export default function NotFound() {
  return (
    <Box
      sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundImage: `url(${parallaxImg})`,
            backgroundSize: "cover",
            width: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        }}
    >
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
    </Box>
  );
}