import React, {Suspense} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spinner from "./components/Spinner";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { PrivateRoute } from './routes';
import './App.css';
import { DataProvider } from './Provider';
import PublicLayout from './components/PublicLayout';
import Admin from './pages/Admin';
import { I18nextProvider  } from 'react-i18next';
import i18n from './locales/i18n';
import NotFound from './pages/NotFound';
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
      <div className="App">
      <DataProvider>
          <PublicLayout>
          <Suspense fallback={<Spinner />}>
              <BrowserRouter>
                    <Routes>
                      <Route
                            path={"/"}
                            element={<Home />}
                      />
                      <Route
                            path={"/admin"}
                            element={<SignIn />}
                      />
                      <Route
                            path={"/admin-panel"}
                            element={
                              <PrivateRoute>
                                <Admin />
                              </PrivateRoute>
                            }
                          />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
              </BrowserRouter>
                </Suspense>
            </PublicLayout>
        </DataProvider>
      </div>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
