import { useState, useEffect } from 'react';
import { projectFireStore } from '../firebase/config';
import { onSnapshot, collection, orderBy } from 'firebase/firestore';

export const useFirestore = (collectionName) => {
  const [docs, setDocs] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(projectFireStore, collectionName),
      orderBy('createdAt', 'desc'),
      (snapshot) => {
        const documents = snapshot.docs.map((doc) => ({ ...doc.data()}));
        const { exterior, products, animations, interior, hdri } = documents[0];
        setDocs({ exterior, products, animations, interior, hdri });
        setLoading(false);
      }
    );

    return () => {
      unsub();
    };
  }, [collectionName]);

  return { docs, loading };
};