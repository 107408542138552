// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDktmk2-3G9atiD5nnLl0VC_XFBxlwNWW4",
  authDomain: "d-vision-bcc11.firebaseapp.com",
  projectId: "d-vision-bcc11",
  storageBucket: "d-vision-bcc11.appspot.com",
  messagingSenderId: "935707876810",
  appId: "1:935707876810:web:5054ba225a68ca20a04333"
};

const app = initializeApp(firebaseConfig);
const projectStorage = getStorage(app);
const projectFireStore = getFirestore(app);
const auth = getAuth(app);

export {projectStorage, projectFireStore, auth}