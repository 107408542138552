import React, {useContext, useEffect, useState} from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DataContext } from '../../Provider';
import { useInView } from 'react-intersection-observer';
import CustomizedMenu from '../CustomizedMenu';
import { useFirestore } from '../../hooks/useFirestore';
import Spinner from "../Spinner"
import { motion } from "framer-motion";
import "./style.css";

const LazyLoadImage = ({ item: {url, description, title, isVideo, url_360, is360}, alt, index }) => {
  const {data, setData} = useContext(DataContext);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px', 
  });

  const handleClick = () => {    
    setData({
      ...data,
      carouselState: { selectedItem: index, toggle: true },
    });

  };

  return (
    <Grid onClick={handleClick} container spacing={2} columns={{ xs: 4, sm: 1, md: 12 }} className={"tile"} style={{margin: "10px"}}>
          {isVideo ? (
              <motion.div
                  ref={ref}
                  className={"nmbvn"}
                  alt={alt}
                  viewport={{ once: true }}
                  loading={inView ? 'auto' : 'lazy'}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={inView ? { opacity: 1, scale: 1} : {}}
                  transition={{ duration: 0.35, ease: 'easeOut' }}
                  whileHover={{ scale: [null, 1.1, 1.2] }}
                >
                <video 
                  key={index} 
                  src={url} 
                  autoPlay={false} 
                  loop
                  muted
                  className={"video-element"}
                  onPointerEnter={(e) => {e.target.play()}} 
                  onPointerLeave={(e) => {e.target.pause()}}
                />
              </motion.div>
            ) : is360 ? (
              <motion.div
                ref={ref}
                className={"nmbvn"}
                alt={alt}
                viewport={{ once: true }}
                loading={inView ? 'auto' : 'lazy'}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={inView ? { opacity: 1, scale: 1} : {}}
                transition={{ duration: 0.35, ease: 'easeOut' }}
                whileHover={{ scale: [null, 1.1, 1.2] }}
              >
                <iframe
                  src={url_360}
                  className="for_360"
                />
              </motion.div>
            ) : (
              <motion.img
                ref={ref}
                src={`${url}?w=248&fit=crop&auto=format`}
                srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                className={"nmbvn"}
                alt={alt}
                viewport={{ once: true }}
                loading={inView ? 'auto' : 'lazy'}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={inView ? { opacity: 1, scale: 1} : {}}
                transition={{ duration: 0.35, ease: 'easeOut' }}
                whileHover={{ scale: [null, 1.1, 1.2] }}
              />
            )}
      <Box className="details">
      <Typography className={"title"} variant="h6" sx={{ my: 2 }}>
        {title}
      </Typography>
      <Typography className={"info"} variant="h6" sx={{ my: 2 }}>
        {description}
      </Typography>
      </Box>
    </Grid>
  );
};

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}


const Gallery = () => {
  const {docs, loading} = useFirestore("images");
  const {data, setData} = useContext(DataContext);
  const [filteredImages, setFilteredImages] = useState([]);
  const [displayedImages, setDisplayedImages] = useState(9);
  const imagesToLoad = 3;
  
  useEffect(() => {
    if(isEmpty(docs)) return;
    if(data.filter === "all") {
      const allValues = Object.values(docs).flat();
      setFilteredImages(allValues)
      setData({...data, images: allValues})
    } else {
      setFilteredImages(docs[data.filter])
      setData({...data, images: docs[data.filter]})
    }
  }, [docs, data.filter])


  return (
    <Box className="gallery">
        <CustomizedMenu />
        <Box alignItems="center" width={"100%"} marginTop="30px" flexWrap="wrap" display="flex" rowGap="15px" justifyContent="space-around">
          {!loading && !!filteredImages.length ? filteredImages.slice(0, displayedImages).map((item, index) => (
            item && <LazyLoadImage key={index} item={item} index={index} alt={"Image"} />
          )) : <Spinner />}
        </Box>
        {filteredImages.length > displayedImages && (
        <Button variant="contained" style={{marginTop: "25px"}} color="primary" onClick={() => setDisplayedImages(displayedImages + imagesToLoad)}>
          Load More
        </Button>
      )}
    </Box>
  );
};

export default Gallery;