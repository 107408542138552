import React, {useContext, useState} from 'react';
import { Box, Tab } from '@mui/material';
import { DataContext } from '../../Provider';
import { TabContext, TabList } from '@mui/lab';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

const options = [
  {title: 'exterior'},
  {title: 'interior'},
  {title: 'all'},
  {title: 'products'},
  {title: 'animations'},
  {title: 'hdri'},
];

export default function CustomizedMenu() {
  const [selectedIndex, setSelectedIndex] = useState(2);
  const {data, setData} = useContext(DataContext);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue);
    setData({...data, filter: options[newValue].title});
  };

  const useStyles = makeStyles(() => ({
    tabBox: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "@media (max-width: 600px)": { // Update to desired breakpoint
        width: "100%",
      },
    },
    tabList: {
      margin:" 0 10px",
    },
    tabLabel: {
      fontWeight: "300 !important",
      "@media (max-width: 600px)": { // Update to desired breakpoint
        fontSize: "10px !important",
        padding:"5px !important",
        minWidth: "65px !important",
      },
      "@media (max-width: 350px)": { // Update to desired breakpoint
        fontSize: "10px !important",
        padding:"5px !important",
        minWidth: "40px !important",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.tabBox}>
      <TabContext value={selectedIndex}>
        <TabList
          onChange={handleChange}
          textColor="black"
          TabIndicatorProps={{
            style: {
              backgroundColor: "grey",
            },
          }}
          aria-label="filter options"
        >
            <Tab className={classes.tabLabel} label={t(options[0].title)} />
            <Tab className={classes.tabLabel} label={t(options[1].title)} />
            <Tab className={classes.tabLabel} label={t(options[2].title)} />
            <Tab className={classes.tabLabel} label={t(options[3].title)} />
            <Tab className={classes.tabLabel} label={t(options[4].title)} />
            <Tab className={classes.tabLabel} label={t(options[5].title)} />
        </TabList>
      </TabContext>
    </Box>
  );
}
