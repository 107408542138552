import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "8px",
    margin: "2rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  contentContainer: {
    marginBottom: "3rem",
    width: "70%",
  },
  headerText: {
    textAlign: "center",
    marginBottom: "3rem",
    color: "#333",
    fontSize: "1.5rem !important",
  },
  bodyText: {
    textAlign: "center",
    marginBottom: "2rem",
    color: "#777",
  },
}));



const About = () => {
  const classes = useStyles();
  const { t } = useTranslation();


  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <Typography style={{margin: "20px 0"}} variant="body1" className={classes.headerText}>
        {t("about_as_header")}
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          {t("about_as_content")}
        </Typography>
      </div>
    </div>
  )
}

export default About