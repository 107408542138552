// import { auth } from "../firebase/config";
import { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    filter: "all", 
    images: [], 
    carouselState: {toggle: false, selectedItem: null},
  });


  return (
    <DataContext.Provider value={{data, setData}}>
      {children}
    </DataContext.Provider>
  );
}
