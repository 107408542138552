import { createTheme } from '@mui/material/styles';
import Logo from "./asstes/Logo.webp";

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      color: '#000000',
    },
    secondary: {
      main: '#fffce3',
      color: '#ffffff',
    },
    logo: {
      main: Logo,
    }
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    fontSize: 16,
  },
  // other theme properties...
});

export default theme;
