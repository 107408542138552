import { Typography } from '@mui/material';
import { Parallax } from 'react-parallax';
import "./style.css"

const ImageParallax = ({ imageUrl, headerText, descriptionText }) => (
    <Parallax className='react-parallax' bgImage={imageUrl}  bgImageAlt="Image" strength={300}>
        <Typography color="white" style={{
          fontSize: "4rem",
          textShadow: "black 3px 3px 8px",
          letterSpacing: "12px",
        }} 
          variant="h2" 
          sx={{ my: 2, "@media (max-width: 800px)": { 
            fontSize: "20px !important",
            letterSpacing: "4px !important",
            } 
          }}>
          {headerText}
        </Typography>
        <Typography color="white" 
        style={{
          fontSize: "24px",
          textShadow: "black 3px 3px 18px",
          textAlign: "center",
          paddingLeft: "10px",
          letterSpacing: "23px",
        }}
        variant="h4" sx={{ my: 2,
        "@media (max-width: 800px)": { 
          fontSize: "16px !important", 
          letterSpacing: "5px !important",
  } }}>{descriptionText}</Typography>
    </Parallax> 
);

export default ImageParallax;
