import { useState, useEffect } from 'react';
import { projectStorage, projectFireStore } from '../firebase/config'
import { ref, uploadBytesResumable, getDownloadURL  } from 'firebase/storage';
import { collection, setDoc, doc, getDoc  } from 'firebase/firestore';

export const useStorage = (done, file, { title, description, option, isVideo, is360, url_360 }) => {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if(!done || !file || !title || !option) return;

        const storageRef = ref(projectStorage, file.name);
        const imagesCollection = collection(projectFireStore, 'images');
        const docRefId  = doc(imagesCollection, 'all_images');
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
          }, (err) => {
            setError(err);
          }, async () => {
            const url = await getDownloadURL(storageRef);
            setUrl(url);

            const existingData = await getDoc(docRefId);
            const existingOptions = existingData.data()[option] || [];

            const newOption = {
              title,
              url,
              description,
              isVideo,
              is360,
              url_360
            };
            
            const updatedOptions = [...existingOptions, newOption];

            const newData = {
              ...existingData.data(),
              [option]: updatedOptions
            };
            
            await setDoc(docRefId, newData);

          })
    }, [done, file])

    return {progress, url, error}
}

